<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <c-card title="중장비 회사 출입 작성" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline>
              <c-btn v-if="editable" color="purple" label="결재요청" icon="check"  />
              <c-btn v-if="editable" label="LBLREMOVE" icon="remove"/>
              <c-btn v-if="editable" label="LBLSAVE" icon="save"  />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-3">
              <c-datepicker
                :required="true"
                :range="true"
                label='출입기간'
                :editable="editable"
                name="requestDt"
                v-model="data.requestDt"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!-- 중장비구분 -->
              <c-select
                type="edit"
                codeGroupCd="HHM_TYPE_CD"
                itemText="codeName"
                itemValue="code"
                name="machineryClassification"
                label="중장비구분"
                v-model="data.machineryClassification"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                :required="true"
                label='중장비'
                :editable="editable"
                name="col1"
                v-model="data.col1"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-field
                :required="true"
                label='운전자'
                :editable="editable"
                name="col3"
                v-model="data.col3"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                :required="true"
                label='공사명'
                :editable="editable"
                name="col2"
                v-model="data.col2"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                label='공사위치'
                :editable="editable"
                name="col4"
                v-model="data.col4"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-field
                label='장비업체'
                :editable="editable"
                name="vendorCd2"
                v-model="data.vendorCd2"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-plant required type="edit" name="plantCd" v-model="data.plantCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-field
                :required="true"
                label='공사업체'
                :editable="editable"
                name="vendorCd"
                v-model="data.vendorCd"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-dept
                :required="true"
                label='공사담당부서'
                :editable="editable"
                name="requestDeptCd"
                v-model="data.requestDeptCd"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-field
                :required="true"
                label='공사담당자'
                :editable="editable"
                name="requestUserId"
                v-model="data.requestUserId"
              />
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'entrance-heavy-equipment-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        safetySuppliesGiveId: '',
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        plantCd: null,
        requestDt: ['2022-12-22', '2022-12-31'],
        requestDeptCd: 'D10002',
        requestUserId: 'U000000001',

        machineryClassification: 'HTC000005',
        col1: '크레인 *******',
        col2: '공사',
        col3: 'U000000001',
        col4: '공사위치',
        vendor1: 'VEN0000001',
        vendor2: 'VEN0000002',
      },
      searchParam: {
        plantCd: null,
        giveDt: '',
        safetySuppliesGiveId: '',
      },
      editable: true,
      disable: false,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.getList();
    },
    getList() {
    },
    apprRequest() { 
    },
    completeRequest() { 
    },
    addrowGive() {
      this.grid.data.splice(0, 0, {

      })
    },
    saveRequest() {
    },
    removeGive() {
    },
    addUser() {
    },
    removeUser() {
    },
  },
};
</script>
